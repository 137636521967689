import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import PUC from "components/common/PageUnderConstruction";
export const _frontmatter = {
  "name": "Street Light Software",
  "shortInfo": "HR management application to track leaves, attendance, payroll, etc. with features like geofencing and employee location tracking for sales teams. Built with Love, React Native and PHP",
  "position": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PUC mdxType="PUC" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      